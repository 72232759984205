
import { defineComponent, PropType, ref } from "vue";
import CollectionsTop from "../shared/CollectionsTop.vue";
import IconText from "../../../shared/components/IconText.vue";
import InputDebounce from "../../../shared/components/InputDebounce.vue";
import { UserCollectionDto, useUserCollections } from "@kunsten/core";

export default defineComponent({
    name: "CollectionEditTop",
    components: { CollectionsTop, IconText, InputDebounce },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isPublic = ref(props.collection.public);
        const title = ref(props.collection.title);
        const loading = ref(false);
        const loadingName = ref(false);
        const { patchUserCollection } = useUserCollections();

        const onInput = (text: string) => {
            updateTitle(text);
        };

        const updateCollection = (data: Partial<{ public: boolean; title: string }>) => {
            patchUserCollection(props.collection.id, data).then(
                () => {
                    loading.value = false;
                    loadingName.value = false;
                    if (!data.title) emit("publicChange", data.public);
                },
                (err) => {
                    console.log(err);
                    loading.value = false;
                    loadingName.value = false;
                }
            );
        };

        const updateTitle = (text: string) => {
            if (text) {
                loadingName.value = true;
                title.value = text;
                updateCollection({ title: text });
            }
        };

        const updateVisibility = (isPublic: boolean) => {
            loading.value = true;
            updateCollection({ public: isPublic });
        };

        return { updateTitle, updateVisibility, onInput, loading, isPublic, title, loadingName };
    },
});
